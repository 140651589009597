var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('DetailPanel',{staticClass:"padded-detail-panel"},[_c('SectionHeading',{attrs:{"padding":"0"}},[_c('SectionH1',[_vm._v("สร้างเลย์เอาต์ใหม่")]),_c('RoundButton',{staticClass:"icon-button bg-blue text-white",nativeOn:{"click":function($event){return _vm.saveLayout.apply(null, arguments)}}},[_c('img',{attrs:{"src":require("@/assets/icons/ic-save.svg")}}),_c('div',[_vm._v("บันทึก")])])],1),_c('section',{attrs:{"id":"new-layout"}},[_c('label',{staticClass:"block"},[_c('span',{staticClass:"text-pale-blue text-16 text-600"},[_vm._v("ชื่อ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.layoutName),expression:"layoutName"}],staticClass:"block",style:({
          border: _vm.isError ? 'solid 2px #ff335c' : '',
        }),attrs:{"type":"text"},domProps:{"value":(_vm.layoutName)},on:{"input":function($event){if($event.target.composing)return;_vm.layoutName=$event.target.value}}})]),_c('div',{attrs:{"id":"new-layout__block-type-selection"}},[_c('div',{staticClass:"block text-pale-blue text-16 text-600"},[_vm._v("ประเภทเลย์เอาต์")]),_c('div',{attrs:{"id":"new-layout__block-types"}},[_vm._l((_vm.layoutTemplates),function(template,i){return _c('div',{key:template.lyt_id,class:[
            'new-layout__template-slot-container',
            {
              hide: _vm.selectedTemplateId !== -1 && _vm.selectedTemplateId !== i,
            },
          ],style:({
            ..._vm.getContainerSize(template.slots),
          }),on:{"click":function($event){_vm.selectedTemplateId === -1 ? _vm.selectBlockType(i) : {}}}},_vm._l((template.slots),function(slot,j){return _c('div',{key:slot.ct_slot_no,class:_vm.selectedTemplateId === i && _vm.isSlotEditable(slot)
                ? 'new-layout__template-slot_hoverable'
                : 'new-layout__template-slot',style:({
              backgroundColor: _vm.gradientColours[j],
              height: `${slot.dflt_pcnt_height * 100}%`,
              left: `${slot.dflt_pcnt_x * 100}%`,
              top: `${slot.dflt_pcnt_y * 100}%`,
              width: `${slot.dflt_pcnt_width * 100}%`,
              zIndex: slot.dflt_z_idx,
            }),on:{"click":function($event){_vm.selectedTemplateId === i && _vm.isSlotEditable(slot) ? _vm.selectSlot(slot) : {}}}},[_vm._v(" สล็อต "+_vm._s(slot.ct_slot_no)+" ")])}),0)}),(_vm.selectedTemplateId !== -1)?_c('BlockSettings',{attrs:{"slotTemplate":_vm.selectingSlot,"selectedTemplate":_vm.layoutTemplates[_vm.selectedTemplateId]},on:{"display-ratio-updated":_vm.updateDisplayRatioType}}):_vm._e()],2)]),(_vm.selectedTemplateId !== -1)?_c('SlotTabs',{attrs:{"selectedTemplate":_vm.layoutTemplates[_vm.selectedTemplateId],"selectingSlotTemplate":_vm.selectingSlot},on:{"manage-content":_vm.openModal,"slot-added":_vm.updateSlots,"slot-selected":_vm.updateSelectingSlot}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }