
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';

import { filter } from 'lodash';

import { gradientColours } from '@/services/helpers';

import BlockSettings from '@/components/layouts/BlockSettings.vue';
import DetailPanel from '@/components/DetailPanel.vue';
import SectionHeading from '@/components/heading/SectionHeading.vue';
import SectionH1 from '@/components/heading/SectionH1.vue';
import SlotTabs from '@/components/layouts/SlotTabs.vue';
import { postLayout } from '@/services/axios';
import { LayoutBlockTemplate, LayoutSlot, LayoutTemplate } from '@/types/index.d';

@Component({
  components: {
    BlockSettings,
    DetailPanel,
    SectionH1,
    SectionHeading,
    SlotTabs,
  },
})
export default class CreateLayout extends Vue {
  @Action loadLayouts!: () => any;
  @State layoutTemplates!: LayoutTemplate[];
  @State opId!: number;

  managingContentAction: string = '';
  managingContentType: string = '';
  isShowingCRUDModal: boolean = false;
  layoutName: string = '';
  selectedTemplateId: number = -1;
  slots: LayoutSlot[] = [];
  isError: boolean = false;

  /**
   * A current selecting layout template's slot. A selecting slot will
   * invoke the change in UI to let user create and set content group to the
   * slot.
   */
  selectingSlot: LayoutBlockTemplate | null = null;

  @Watch('layoutName')
  onLayoutNameChanged() {
    this.isError = false;
  }

  get gradientColours(): string[] {
    return gradientColours;
  }

  /**
   * Computed. Get editable layout template's slots. The editable slots
   * are the slots which contaning rcmd_ct_typ (recommened content type) in [0, 1, 2].
   * @returns {LayoutBlockTemplate[]} a list of editable slots.
   */
  get editableSlots(): LayoutBlockTemplate[] {
    return filter(this.layoutTemplates[this.selectedTemplateId].slots, (s: LayoutBlockTemplate) =>
      this.isSlotEditable(s)
    ) as LayoutBlockTemplate[];
  }

  /**
   * A function to check if a given layout template's slot is editable or not.
   * A slot which contaning rcmd_ct_typ (recommened content type) in [0, 1, 2] is editable.
   * @param {LayoutBlockTemplate} slot a layout template's slot to check if it's editable.
   * @returns {boolean} true if a slot is editable, false otherwise.
   */
  isSlotEditable(slot: LayoutBlockTemplate): boolean {
    return slot.rcmd_ct_typ === 0 || slot.rcmd_ct_typ === 1 || slot.rcmd_ct_typ === 2;
  }

  closeModal(): void {
    this.isShowingCRUDModal = false;
  }

  getContainerSize(blocks: any[]): object {
    return {
      height: this.selectedTemplateId === -1 ? '17rem' : '31rem',
      width: this.selectedTemplateId === -1 ? '30rem' : '55rem',
    };
  }

  openModal([contentAction, contentType]: [string, string]): void {
    this.managingContentAction = contentAction;
    this.managingContentType = contentType;
    this.isShowingCRUDModal = true;
  }

  saveLayout(): void {
    if (this.layoutName == null || this.layoutName.length == 0) {
      this.isError = true;
      return;
    }
    const preparedLayout = {
      name: this.layoutName,
      is_act: true,
      width: 1920,
      height: 1080,
      ratio: 1.77777,
      slots: this.slots,
    };

    postLayout(this.opId, preparedLayout)
      .then(this.loadLayouts)
      .then(() => this.$emit('layout-created'));
  }

  selectBlockType(blockType: number): void {
    this.selectedTemplateId = blockType;
    this.selectingSlot = this.editableSlots[0];
  }

  /**
   * A function to select a slot in a layout template.
   * @param {LayoutBlockTemplate} slot a selected slot of a layout template.
   */
  selectSlot(slot: LayoutBlockTemplate): void {
    this.selectingSlot = slot;
  }

  /**
   * A function to update the selecting layout template's slot from the created
   * slot. This function will find a matching slot template i.e. ct_slot_no is equal
   * to the created slot and call `selectSlot()`.
   * @param {LayoutSlot} slot a created slot.
   */
  updateSelectingSlot(slot: LayoutSlot): void {
    const selectingSlot: LayoutBlockTemplate | undefined = this.editableSlots.find(
      (layoutSlotTemplate: LayoutBlockTemplate) => layoutSlotTemplate.ct_slot_no === slot.ct_slot_no
    );
    if (selectingSlot) {
      this.selectSlot(selectingSlot);
    }
  }

  updateDisplayRatioType(): void {}

  updateSlots(slots: LayoutSlot[]): void {
    this.slots = slots;
  }
}
