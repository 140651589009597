
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { State } from 'vuex-class';

import { Playlist } from '@/types/index.d';
import Modal from '@/components/Modal.vue';
import SectionH1 from '@/components/heading/SectionH1.vue';

type ContentAction = 'create' | 'update';
type ContentType = 'playlist' | 'website';

@Component({
  components: {
    Modal,
    SectionH1,
  },
  filters: {
    twoDigits(val: number | string): string {
      return `${val}`.padStart(2, '0');
    },
  },
})
export default class PlaylistCRUD extends Vue {
  @Prop(String) readonly contentAction!: ContentAction;
  @Prop(String) readonly contentType!: ContentType;

  @State playlists!: Playlist[];

  endDate: Date = new Date();
  endShowingTimeHH: number = 0;
  endShowingTimeMM: number = 0;
  enteredURL: string = '';
  playlistPlaybackType: number = 0;
  selectedPlaylistId: number = 0;
  showingDateType: number = 0;
  showingTimeType: number = 1;
  startDate: Date = new Date();
  startShowingTimeHH: number = 0;
  startShowingTimeMM: number = 0;

  get actionH1Text(): string {
    /* eslint-disable */
    if (this.contentType === 'playlist' && this.contentAction === 'create')
      return 'ใส่เพลย์ลิสต์ใหม่';
    if (this.contentType === 'playlist' && this.contentAction === 'update')
      return 'แก้ไขเพลย์ลิสต์';
    if (this.contentType === 'website' && this.contentAction === 'create') return 'ใส่เว็บไซต์ใหม่';
    if (this.contentType === 'website' && this.contentAction === 'update') return 'แก้ไขเว็บไซต์';
    /* eslint-enable */
    return 'Hmmm... there must be something wrong 🤔';
  }

  get showingOptions(): string[] {
    return ['ตลอดเวลา', 'กำหนดเอง'];
  }

  get isWholeDayDisplaying() {
    if (
      this.startShowingTimeHH == 0 &&
      this.startShowingTimeMM == 0 &&
      this.endShowingTimeHH == 0 &&
      this.endShowingTimeMM == 0
    ) {
      return true;
    }
    return false;
  }

  addPlaylist(): void {
    let contentType = 0;
    let contain = '';
    let startAt = '';
    let endAt = '';

    if (this.contentType === 'playlist') {
      contentType = 1;
      contain = `${this.selectedPlaylistId}`;
    } else if (this.contentType === 'website') {
      contentType = 2;
      contain = this.enteredURL;
    }

    if (this.showingDateType === 0 || this.isWholeDayDisplaying) {
      // Let's hope this will not be used until the end of the century! 🤞🤞🤞
      startAt = '2000-01-01';
      endAt = '2099-12-31';
    } else {
      this.startDate.setHours(this.startDate.getHours() + 7);
      this.endDate.setHours(this.endDate.getHours() + 7);

      startAt = this.startDate.toISOString().split('T')[0];
      endAt = this.endDate.toISOString().split('T')[0];
    }

    let startTimeSeconds = 0;
    let endTimeSeconds = 86399;

    if (this.showingTimeType === 0 || this.isWholeDayDisplaying) {
      startAt += 'T00:00:00+07:00';
      endAt += 'T23:59:59+07:00';
    } else {
      startAt += `T${this.startShowingTimeHH
        .toString()
        .padStart(2, '0')}:${this.startShowingTimeMM.toString().padStart(2, '0')}:00+07:00`;
      endAt += `T${this.endShowingTimeHH
        .toString()
        .padStart(2, '0')}:${this.endShowingTimeMM.toString().padStart(2, '0')}:00+07:00`;

      startTimeSeconds = this.startShowingTimeHH * 60 * 60 + this.startShowingTimeMM * 60;
      endTimeSeconds = this.endShowingTimeHH * 60 * 60 + this.endShowingTimeMM * 60;

      if (endTimeSeconds == 0 && startTimeSeconds > 0) {
        endTimeSeconds = 86400;
      }
    }

    const preparedContent = {
      ct_typ: contentType,
      loc_id: 2,
      contain: contain,
      is_shuffle: false,
      is_mute: false,
      start_at: new Date(startAt).toISOString(),
      end_at: new Date(endAt).toISOString(),
      time_flag: 1,
      time_sun: [[startTimeSeconds, endTimeSeconds]],
      time_mon: [],
      time_tue: [],
      time_wed: [],
      time_thu: [],
      time_fri: [],
      time_sat: [],
      remark_flag: 0,
      time_remark: [],
    };

    this.$emit('content-added', preparedContent);
  }

  closeModal(): void {
    this.$emit('close-modal');
  }
}
