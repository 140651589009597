
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { filter, some } from 'lodash';

import { Layout, LayoutSlot, Playlist } from '@/types/index.d';

import DetailPanel from '@/components/DetailPanel.vue';
import SectionHeading from '@/components/heading/SectionHeading.vue';
import SectionH1 from '@/components/heading/SectionH1.vue';
import SectionH2 from '@/components/heading/SectionH2.vue';
import Card from '@/components/Card.vue';

import { gradientColours, getFormattedTimeInfo } from '@/services/helpers';
import { deleteLayout } from '@/services/axios';

@Component({
  components: {
    DetailPanel,
    SectionH1,
    SectionH2,
    SectionHeading,
    Card,
  },
})
export default class LayoutInfo extends Vue {
  @Action loadLayouts!: () => any;
  @State contentGroups!: any;
  @State playlists!: Playlist[];
  @State screens!: Screen[];
  @Prop() readonly selectedLayout!: Layout;

  get editableSlots(): LayoutSlot[] {
    return filter(this.selectedLayout.slots, (s: any) => s.ct_grp_id) as LayoutSlot[];
  }

  get gradientColours(): string[] {
    return gradientColours;
  }

  get mappedEditableSlots(): any[] {
    return this.editableSlots.map(s => ({
      ...s,
      ct_grp: this.contentGroups.find((ctg: any) => ctg.ct_grp_id === s.ct_grp_id),
    }));
  }

  get screensUsingCurrentscreen(): any[] {
    const { lyt_id } = this.selectedLayout;
    return filter(this.screens, (s: any) => {
      return some(s.schedules, (sc: any) => sc.lyt_id === lyt_id);
    });
  }

  getFormattedTimeInfo(startAt: Date, endAt: Date, timeSun?: any): string {
    return getFormattedTimeInfo(startAt, endAt, timeSun);
  }

  deleteLayout(): void {
    if (!this.selectedLayout) return;

    if (window.confirm('คุณต้องการลบเลย์เอาท์นี้หรือไม่?')) {
      deleteLayout(this.selectedLayout.op_id, this.selectedLayout.lyt_id)
        .then(this.loadLayouts)
        .then(() => this.$emit('layout-deleted'));
    }
  }

  editLayout(): void {
    this.$emit('edit-layout');
  }

  getPlaylistName(playlistId: any[]): string {
    const playlist = this.playlists.find((pl: any) => pl.pl_id === playlistId);
    return playlist ? playlist.name : `ไม่พบเพลย์ลิสต์ เพลย์ลิสต์อาจถูกลบไปแล้ว (${playlistId})`;
  }
}
