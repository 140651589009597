
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';

import { Playlist } from '@/types/index.d';
import Modal from '@/components/Modal.vue';
import PlaylistCRUD from '@/components/layouts/PlaylistCRUD.vue';
import SectionH1 from '@/components/heading/SectionH1.vue';
import { postContentGroup, putContentGroup } from '@/services/axios';
import { getFormattedTimeInfo } from '@/services/helpers';

@Component({
  components: {
    Modal,
    PlaylistCRUD,
    SectionH1,
  },
})
export default class SlotContentModal extends Vue {
  @Prop() readonly selectedSlot!: any;
  @Prop() readonly initialContentGroups: any[] | undefined;

  @Action loadContentGroups!: () => any;
  @State playlists!: Playlist[];
  @State opId!: number;

  isShowingCRUDModal: boolean = false;
  managingContentAction: string = '';
  managingContentType: string = '';

  contentGroup: any[] = [];

  mounted() {
    if (this.initialContentGroups) {
      this.initialContentGroups.forEach(group =>
        this.addContentToGroup({
          loc_id: 2,
          ct_typ: group.ct_typ,
          contain: group.contain,
          is_shuffle: group.is_shuffle,
          is_mute: group.is_mute,
          start_at: `${group.start_at}Z`,
          end_at: `${group.end_at}Z`,
          time_flag: group.time_flag,
          time_sun: group.time_sun,
          time_mon: group.time_mon,
          time_tue: group.time_tue,
          time_wed: group.time_wed,
          time_thu: group.time_thu,
          time_fri: group.time_fri,
          time_sat: group.time_sat,
          remark_flag: group.remark_flag,
          time_remark: group.time_remark,
        })
      );
    }
  }

  get mappedContentGroup(): any[] {
    return this.contentGroup.map(c => {
      if (c.ct_typ === 1) {
        const playlist = this.playlists.find(pl => pl.pl_id === parseInt(c.contain, 10));
        return playlist ? { ...c, name: playlist.name } : `เพลย์ลิสต์ id: ${c.contain}`;
      }
      return {
        ...c,
        name: c.contain,
      };
    });
  }

  getFormattedTimeInfo(startAt: Date, endAt: Date, timeSun?: any): string {
    return getFormattedTimeInfo(startAt, endAt, timeSun);
  }

  addContentToGroup(content: any): void {
    this.isShowingCRUDModal = false;
    this.contentGroup.push(content);
  }

  deleteContent(index: number): void {
    this.contentGroup = this.contentGroup.filter((ctg, i) => i !== index);
  }

  closePlaylistCRUDModal(): void {
    this.isShowingCRUDModal = false;
  }

  closeSlotContentModal(): void {
    this.$emit('close-modal');
  }

  manageContent(action: string, type: string): void {
    this.isShowingCRUDModal = true;
    this.managingContentAction = action;
    this.managingContentType = type;
  }

  move(index: number, diff: number): void {
    const temp = this.contentGroup[index];
    this.$set(this.contentGroup, index, this.contentGroup[index + diff]);
    this.$set(this.contentGroup, index + diff, temp);
  }

  addSlot(): void {
    const preparedContentGroup = {
      is_act: true,
      name: `Content group (${new Date().toISOString()})`,
      schedules: this.contentGroup,
    };

    const onSuccess = (res: any) => {
      const newId = res.data.new_id;
      this.$emit('content-group-created', [newId, this.mappedContentGroup]);
      this.loadContentGroups().then(this.closeSlotContentModal);
    };

    if (this.selectedSlot.ct_grp_id) {
      putContentGroup(this.opId, this.selectedSlot.ct_grp_id, preparedContentGroup).then(onSuccess);
    } else {
      postContentGroup(this.opId, preparedContentGroup).then(onSuccess);
    }
  }
}
