
import { Component, Vue } from 'vue-property-decorator';
import { State, Mutation } from 'vuex-class';

import { Layout } from '@/types/index.d';

import CreateLayout from '@/components/layouts/CreateLayout.vue';
import DetailPanel from '@/components/DetailPanel.vue';
import EditLayout from '@/components/layouts/EditLayout.vue';
import LayoutInfo from '@/components/layouts/LayoutInfo.vue';
import SectionHeading from '@/components/heading/SectionHeading.vue';
import SectionH1 from '@/components/heading/SectionH1.vue';
import SectionH2 from '@/components/heading/SectionH2.vue';
import SidePanel from '@/components/nav/SidePanel.vue';
import SidePanelItemBlock from '@/components/nav/SidePanelItemBlock.vue';

@Component({
  components: {
    CreateLayout,
    DetailPanel,
    EditLayout,
    LayoutInfo,
    SectionH1,
    SectionH2,
    SidePanel,
    SidePanelItemBlock,
    SectionHeading,
  },
})
export default class LayoutsView extends Vue {
  @State layouts!: Layout[];

  mode: string = 'view';
  selectedLayout: Layout | null = null;

  editLayout(): void {
    this.mode = 'edit';
  }

  layoutCreated(): void {
    this.mode = 'view';
    this.selectedLayout = null;
  }

  layoutEdited(): void {
    this.mode = 'view';
    this.selectedLayout = null;
  }

  layoutDeleted(): void {
    this.selectedLayout = null;
  }

  openCreateLayoutModal(): void {
    this.selectedLayout = null;
    this.mode = 'create';
  }

  selectLayout(layout: Layout): void {
    this.mode = 'view';
    this.selectedLayout = layout;
  }
}
