
import { Component, Prop, Vue } from 'vue-property-decorator';
import { LayoutBlockTemplate } from '@/types/index.d';

@Component({
  components: {},
})
export default class BlockSettings extends Vue {
  @Prop() slotTemplate!: LayoutBlockTemplate;
  displayRatioTypeId: number = 1;

  updateDisplayRatioType(): void {
    this.$emit('display-ratio-updated');
  }
}
