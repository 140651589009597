
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { State } from 'vuex-class';

import { Dictionary, map } from 'lodash';

import SlotContentModal from '@/components/layouts/SlotContentModal.vue';
import { getFormattedTimeInfo } from '@/services/helpers';
import { editableBlockType } from '@/utils/layout';
import { LayoutBlockTemplate, LayoutSlot, LayoutTemplate } from '@/types/index.d';

@Component({
  components: {
    SlotContentModal,
  },
})
export default class SlotTabs extends Vue {
  @Prop() readonly selectedTemplate!: LayoutTemplate;
  @Prop() selectingSlotTemplate!: LayoutBlockTemplate;
  @State contentGroups!: any[];

  /**
   * Current selecting layout slot which is used to show the content groups list.
   */
  selectingSlot: LayoutSlot | null = null;
  /**
   * A list of created layout slots which is resulted from `selectedTemplate` property.
   */
  createdSlots: LayoutSlot[] = [];
  isShowingSlotContentModal: boolean = false;
  contents: Dictionary<any[]> = {};

  /**
   * Called when the `selectedTemplate` is changed. This function will create layout slots
   * from the layout template. Therefore, the created slots are ready to set up their
   * content group.
   */
  @Watch('selectedTemplate')
  onSelectedTemplateChanged() {
    this.createdSlots = map(this.selectedTemplate.slots, (slotTemplate: LayoutBlockTemplate) => {
      return {
        ct_slot_no: slotTemplate.ct_slot_no,
        can_move: slotTemplate.can_move,
        can_change: slotTemplate.can_change,
        x: slotTemplate.dflt_x,
        y: slotTemplate.dflt_y,
        width: slotTemplate.dflt_width,
        height: slotTemplate.dflt_height,
        pcnt_x: slotTemplate.dflt_pcnt_x,
        pcnt_y: slotTemplate.dflt_pcnt_y,
        pcnt_width: slotTemplate.dflt_pcnt_width,
        pcnt_height: slotTemplate.dflt_pcnt_height,
        z_idx: slotTemplate.dflt_z_idx,
        rcmd_ct_typ: slotTemplate.rcmd_ct_typ,
        disp_ratio_typ_id: 1, // TODO: use value from BlockSettings
        ct_grp_id: null,
      } as LayoutSlot;
    });
  }

  /**
   * Called when the `selectingSlotTemplate` is changed. This will call
   * `selectSlot` under the hood. `selectingSlotTemplate` is normally changed
   * when the user clicks at the slot div rectangle in the CreateLayout view.
   */
  @Watch('selectingSlotTemplate')
  onSelectingSlotTemplateChanged() {
    this.selectSlot(this.selectingSlotTemplate);
  }

  get createdSlotIds(): number[] {
    return this.createdSlots.map(sl => sl.ct_slot_no);
  }

  get slotsInTemplate(): any[] {
    if (!this.selectedTemplate) return [];
    // @ts-ignore
    // Get only non-special blocks
    return this.selectedTemplate.slots.filter(s => editableBlockType.includes(s.rcmd_ct_typ));
  }

  addContentGroupToLayout([contentGroupId, contents]: [number, any[]]) {
    if (this.selectingSlot) {
      this.contents[this.selectingSlot.ct_slot_no] = contents;
      this.selectingSlot.ct_grp_id = contentGroupId;
    }
    this.$emit('slot-added', this.createdSlots);
  }

  closeModal(): void {
    this.isShowingSlotContentModal = false;
  }

  contentAction(action: string, contentType: string): void {
    this.$emit('manage-content', [action, contentType]);
  }

  openSlotContentModal(): void {
    this.isShowingSlotContentModal = true;
  }

  /**
   * A function to set a selecting slot from the layout template's slot.
   * This function also emit `slot-selected` which is a created slot.
   * @param {LayoutBlockTemplate} slot a selected slot.
   */
  selectSlot(slot: LayoutBlockTemplate): void {
    const foundSelectingSlot: LayoutSlot | undefined = this.createdSlots.find(
      (createdSlot: LayoutSlot) => createdSlot.ct_slot_no === slot.ct_slot_no
    );
    if (foundSelectingSlot && foundSelectingSlot !== this.selectingSlot) {
      this.selectingSlot = foundSelectingSlot;
      this.$emit('slot-selected', this.selectingSlot);
    }
  }

  getFormattedTimeInfo(startAt: Date, endAt: Date, timeSun?: any): string {
    return getFormattedTimeInfo(startAt, endAt, timeSun);
  }

  mounted() {
    this.onSelectedTemplateChanged();
    this.onSelectingSlotTemplateChanged();
  }
}
