
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { filter } from 'lodash';

import { gradientColours, getFormattedTimeInfo } from '@/services/helpers';

import BlockSettings from '@/components/layouts/BlockSettings.vue';
import DetailPanel from '@/components/DetailPanel.vue';
import SectionHeading from '@/components/heading/SectionHeading.vue';
import SectionH1 from '@/components/heading/SectionH1.vue';
import SlotContentModal from '@/components/layouts/SlotContentModal.vue';
import SlotTabs from '@/components/layouts/SlotTabs.vue';
import { postReplaceLayout } from '@/services/axios';
import { editableBlockType } from '@/utils/layout';

@Component({
  components: {
    BlockSettings,
    DetailPanel,
    SectionH1,
    SectionHeading,
    SlotContentModal,
    SlotTabs,
  },
})
export default class EditLayout extends Vue {
  @Action loadLayouts!: () => any;
  @Action loadContentGroups!: () => any;
  @State contentGroups!: any[];
  @State opId!: number;
  @State playlists!: any[];
  @State layoutTemplates!: any[];

  @Prop() readonly selectedLayout!: any;

  newlyCreatedBlock: number = 0;
  managingContentAction: string = '';
  managingContentType: string = '';
  isShowingCRUDModal: boolean = false;
  /**
   * A current editing slot which results in showing slot content modal.
   */
  editingSlot: any | null = null;
  layoutName: string = '';
  slots: any[] = [];
  selectedTemplateId: number = -1;
  isError: boolean = false;

  mounted() {
    this.layoutName = this.selectedLayout.name;
  }

  @Watch('layoutName')
  onLayoutNameChanged() {
    this.isError = false;
  }

  /**
   * Computed. Get editable slots. An editable slot is a slot contaning rcmd_ct_typ in
   * [0, 1, 2].
   */
  get editableSlots(): any[] {
    return filter(
      this.selectedLayout.slots,
      (s: any) => s.rcmd_ct_typ === 0 || s.rcmd_ct_typ === 1 || s.rcmd_ct_typ === 2
    );
  }

  getContainerSize(blocks: any[]): object {
    return {
      height: this.selectedTemplateId === -1 ? '17rem' : '31rem',
      width: this.selectedTemplateId === -1 ? '30rem' : '55rem',
    };
  }

  get slotsInTemplate(): any[] {
    // @ts-ignore
    // Get only non-special blocks
    if (this.selectedLayout.slots) {
      return filter(this.selectedLayout.slots, s => editableBlockType.includes(s.rcmd_ct_typ));
    }

    return this.layoutTemplates[this.selectedTemplateId];
  }

  get mappedEditableSlots(): any[] {
    return this.editableSlots.map(s => ({
      ...s,
      ct_grp: this.contentGroups.find((ctg: any) => ctg.ct_grp_id === s.ct_grp_id),
    }));
  }

  get gradientColours(): string[] {
    return gradientColours;
  }

  getFormattedTimeInfo(startAt: Date, endAt: Date, timeSun?: any): string {
    return getFormattedTimeInfo(startAt, endAt, timeSun);
  }

  addContentGroupToLayout([newId, mappedContentGroup]: [any, any]): void {
    this.newlyCreatedBlock = newId;
  }

  closeModal(): void {
    this.editingSlot = null;
  }

  getPlaylistName(playlistId: any[]): string {
    const playlist = this.playlists.find((pl: any) => pl.pl_id === playlistId);
    return playlist ? playlist.name : `ไม่พบเพลย์ลิสต์ เพลย์ลิสต์อาจถูกลบไปแล้ว (${playlistId})`;
  }

  openModal([contentAction, contentType]: [string, string]): void {
    this.managingContentAction = contentAction;
    this.managingContentType = contentType;
    this.isShowingCRUDModal = true;
  }

  openSlotContentModal(slot: any): void {
    this.editingSlot = slot;
  }

  selectBlockType(blockType: number): void {
    this.selectedTemplateId = blockType;
  }

  saveLayout(): void {
    if (this.layoutName == null || this.layoutName.length == 0) {
      this.isError = true;
      return;
    }
    let slots: any[] = this.slots;

    if (this.selectedLayout.slots) {
      const unchangableSlots = filter(
        this.selectedLayout.slots,
        (s: any) => !editableBlockType.includes(s.rcmd_ct_typ)
      );
      const changableSlots = this.editableSlots.map(s => ({
        ...s,
        ct_grp_id: this.newlyCreatedBlock ? this.newlyCreatedBlock : s.ct_grp_id,
      }));

      slots = [...unchangableSlots, ...changableSlots];
    }

    const preparedLayout = {
      name: this.layoutName,
      is_act: true,
      width: this.selectedLayout.width,
      height: this.selectedLayout.height,
      ratio: this.selectedLayout.ratio,
      slots,
    };

    postReplaceLayout(this.opId, this.selectedLayout.lyt_id, preparedLayout)
      .then(this.loadLayouts)
      .then(this.loadContentGroups)
      .then(() => this.$emit('layout-edited'));
  }

  updateDisplayRatioType(): void {}

  updateSlots(slot: any): void {
    this.slots = slot;
  }
}
